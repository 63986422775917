.sidebar-items-container,
.sidebar-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 5rem;
  min-height: 100vh;
  background: #6923d0;
  padding: 1.25em 0em 1.25em 0em;
  box-shadow: 2px 2px 3px #b3bec9;
}

.sidebar-container-mobile {
  transition-timing-function: ease-in-out;
  max-width: 14rem;
}

.sidebar-item,
.sidebar-item-active {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font: "Poppins", sans-serif;
  font-weight: 600;
  color: #afa5d9;
  border: 2px solid #6923d0;
}

.sidebar-container-mobile > a > .sidebar-item,
.sidebar-container-mobile > a > .sidebar-item-active {
  min-width: 10rem;
}

.sidebar-container-mobile > a > .sidebar-item-active {
  color: #f9f6fd;
}

.sidebar-item-active {
  border: 2px solid #afa5d9;
  border-radius: 5px;
}

.react-icons,
.react-icons-active {
  vertical-align: middle;
  color: #afa5d9;
  padding: 0.75em 0.75em;
}

.react-icons-active {
  color: #f9f6fd;
  padding-left: 0.5em;
}

.sidebar-container-mobile > a:nth-child(1),
.sidebar-items-container > a:nth-child(1) {
  margin-bottom: 2em;
}

.sidebar-item > a:nth-child(1) > div:nth-child(1),
.sidebar-container-mobile > a:nth-child(1) > div:nth-child(1) {
  color: white;
}

.sidebar-container-mobile > a:nth-child(11),
.sidebar-items-container > a:nth-child(11) {
  margin-top: auto;
}
