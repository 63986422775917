.logo-container {
    display: flex;
    justify-content: center;
    padding:2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-container > src{
    padding:5em;
    width: 40%;
    height: 40%;
}


