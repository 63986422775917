.labels_container {
  padding: 1em;
  max-width: 740px;
  min-height: 5rem;
  border-radius: 5px;
}

.label {
  display: flex;
  flex-wrap: wrap;
}

.label > * {
  margin: 4px;
}

.labels_heading {
  padding-bottom: 0.5em;
  color: #3f51b5;
}

.labels_heading > span:nth-child(1) {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
}

.labels_heading > span:nth-child(2) {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0.75rem;
}

.cross {
  background: #e57373;
  border-radius: 5px;
  height: 3rem;
  width: 20px;
  color: white;
  padding: 0.2em;
}
