.login_main_container {
  display : flex;
  justify-content : center;
  min-height: 100%;
  padding-top:3em;
} 

.login{
  width: 350px;
  max-height: 400px;
  display : flex;
  flex-direction: column;
  border-top: 3px solid #5553ff;
}

.login_form{
  display : flex;
  flex-direction: column;
  align-items: center;
}

.login>*,
.login_form>*{
  margin:0.5rem 0.5rem 0.5rem 0.5rem;
}

.welcome-text{
  padding:0.5rem 0.5rem 0.5rem 0rem;
  color: #5553ff;
  font:525 1.5rem Nunito Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Helvetica Neue;
}

.login_header{
  font:550 0.8rem Nunito Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Helvetica Neue;
}

#username,#password{
  border-radius:0;
  min-width:100%;
  min-height: 3em;
  font-weight:700;
  padding:1em;
  margin-left:0px;
}

#forgot-password,
#forgot-password > a{
  font:550 0.8rem Nunito Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Helvetica Neue;
  margin-left:auto;
  color:#5553ff;
}

.login-button{
  min-width:100%;
  margin-left:0px;
}

.alert{
  font:400 0.8rem Nunito Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Helvetica Neue;
  color:red;
  align-items:center;
}

#register-text > span{
  font:400 0.8rem Nunito Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Helvetica Neue;
  margin-right: 0.5em;;
}

#register-text > a{
  font:600 0.8rem Nunito Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Helvetica Neue;
  color:#5553ff;
}