select {
  font-family: Inter, sans-serif;
  background: #f5f3f4;
  padding: 0.75em;
  color: black;
  border-radius: 5px;
  max-width: 20rem;
  height: rem;
  outline: none;
  border: none;
}
