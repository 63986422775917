
/*  font-family: Abel  */
@font-face {
    font-family: Abel;
    src: url('../fonts/Abel/Abel-Regular.ttf');
}

/*  font-family: Anton  */
@font-face {
    font-family: Anton;
    src: url('../fonts/Anton/Anton-Regular.ttf');
}

/*  font-family: Josefin  */
@font-face {
    font-family: Josefin;
    src: url('../fonts/Josefin_Sans/JosefinSans-Regular.ttf');
}

/*  font-family: Lexend  */
@font-face {
    font-family: Lexend;
    src: url('../fonts/Lexend_Deca/LexendDeca-Regular.ttf');
}

/*  font-family: Livvic  */
@font-face {
    font-family: Livvic;
    src: url('../fonts/Livvic/Livvic-Regular.ttf');
}