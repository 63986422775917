* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  color: white;
}

.app_container {
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-template-rows: 4rem 1fr 2rem;
  min-height: 100vh;
}

.app_container_mobile {
  display: grid;
  grid-template-columns: 12rem 1fr;
  grid-template-rows: 4rem 1fr 2rem;
  min-height: 100vh;
}

.sidebar-items-container,
.sidebar-container-mobile {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: -1;
}

.topbar-container {
  grid-column-start: 2;
  grid-column-end: -1;
  grid-row-start: 1;
  grid-row-end: 2;
}

.content_container {
  grid-column-start: 2;
  grid-column-end: -1;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  justify-content: center;
  padding: 1em;
}

.footer-conatiner {
  grid-column-start: 2;
  grid-column-end: -1;
  grid-row-start: 3;
  grid-row-end: -1;
}
