button {
  font-weight: 700;
  font-family: Inter, sans-serif;
  outline: none;
  border: none;
  background: #227aeb;
  border-radius: 5px;
  padding: 0.5em;
  color: white;
  width: 10rem;
  height: 3rem;
}

button:hover {
  transition: all 0.15s ease;
  background: #3f51b5;
}
