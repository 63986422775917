.dataSet_form_creation {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.dataSet_form_creation > * {
  margin: 10px;
  box-shadow: 1px 1px 4px #b3bec9;
}

.description {
  max-width: 100%;
}

.file {
  min-width: 15rem;
  min-height: 2.5rem;
  font-weight: 550;
}

.warning {
  color: #e57373;
  font-size: 0.8rem;
  box-shadow: 0px 0px;
}
