@import url(./css/fonts.css);

:root {
  --text-gray: #3f4954;
  --text-light: #686666da;
  --bg-color: #0f0f0f;
  --white: #ffffff;
  --purple: #003f88;
  --midnight: #104f55;
  --newblue: #0096c7;
  --sky: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
  --Abel: "Abel", cursive;
  --Anton: "Anton", cursive;
  --Josefin: "Josefin", cursive;
  --Lexend: "Lexend", cursive;
  --Livvic: "Livvic", cursive;
}

.main-navbar-conatainer {
  display: flex;
  height: 4rem;
  box-shadow: 0 0 10px 0 rgb(94, 96, 206, 0.3);
}

.tittle-homepage{
  display: flex;
  align-items: center;
  justify-items: center;
  color: #5553ff;
  font:900 1.8rem sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Helvetica Neue;
  height:100%;
  margin-left:0.5em;
}

.nav-bar-container-small {
  display: flex;
  margin-left: auto;
  justify-items: center;
}

.nav-bar-container-small> * {
  display: flex;
  align-items: center;
  justify-items: center;
}

.nav-bar-container-small div {
  padding: 0.5em 0.5em;
  color: #5553ff;
  font:550 1rem Nunito Sans,sans-serif,-apple-system,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Helvetica Neue;
}

.nav-bar-container-small div:last-child {
  background:#5553ff;
  color: white;
}

.nav-bar-container-small div:hover{
  color: white;
  background-color: gray;
}
