.topbar-container {
  display: flex;
  flex-direction: row;
  min-height: 4rem;
  padding: 1.25em;
  padding-left: 2.5em;
  box-shadow: 0px 0px 4px #b3bec9;
}

.current-selected-core-module {
  display: flex;
  font-weight: bold;
  text-transform: uppercase;
}

.current-selected-core-module > span {
  font-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em 0.1em;
  background: #6923d0;
  color: white;
  border-radius: 5px;
  padding: 0.5em;
}

.topbar-profile-items {
  display: flex;
  margin-left: auto;
}

.topbar-profile-items > * {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.user-name {
  font-family: Roboto;
  font-weight: bold;
  font-size: 1em;
  color: #2cc8d2;
}

.user-icon,
.notification {
  color: #2c82d2;
}

.coins {
  color: #edd716;
}

.right-arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6923d0;
}
