input {
  font-family: Inter, sans-serif;
  outline: none;
  border: none;
  background: #f5f3f4;
  padding: 0.5em;
  border-radius: 5px;
  max-width: 10rem;
  height: 3rem;
}
