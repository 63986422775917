.footer-conatiner {
  display: flex;
  padding: 0em 2em;
  box-shadow: 0px 0px 4px #b3bec9;
}
.footer-icons {
  display: flex;
  margin-left: auto;
}

.footer-icons > * {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.linkdin-icon {
  color: blue;
}

.youtube-icon {
  color: red;
}

.twitter-icon {
  color: skyblue;
}

.facebook-icon {
  color: blue;
}

.discord-icon {
  color: blue;
}

.text-warning {
  display: flex;
  align-items: center;
  font-family: IBM Plex Mono;
  font-size: 75%;
}
